import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'
import Copy from './Copy'
import Headline from './Headline'
import IconButton from './IconButton'
import StyledLink from './StyledLink'

const Footer: React.FC = (): JSX.Element => (
    <div className={css(styles.Footer)}>
        <div className={css(styles.content)}>
            <div className={css(styles.block)}>
                <Headline type="h4" left={true} text="KONTAKT & SERVICE" />

                <Copy small>
                    Du erreichst uns von Mo. - Fr. von 11 - 18 Uhr
                    <br />
                    Per Telefon 0221 168 681 30 oder per
                    <br />
                    E-Mail{' '}
                    <StyledLink small linkTarget="mailto:hallo@bride-surprise.com">
                        hallo@bride-surprise.com
                    </StyledLink>
                </Copy>
            </div>

            <div className={css(styles.block)}>
                <Headline type="h4" left={true} text="JGA PRODUKTE" />

                <Copy small>
                    Du brauchst noch ausgefallene Produkte für den bevorstehenden JGA? <br />
                    Dann besuch unseren Onlineshop{' '}
                    <StyledLink small linkTarget="http://www.fawntastique.com/">
                        Fawntastique
                    </StyledLink>
                    .
                </Copy>
            </div>

            <div className={css(styles.block)}>
                <Headline type="h4" left={true} text="SEI DABEI" />

                <Copy small>
                    Du möchtest keinen Tipp mehr verpassen und live mit dabei sein, wenn wir einen JGA planen und
                    umsetzen? Dann folge uns auf Social Media.
                </Copy>

                <div className={css(styles.icons)}>
                    <IconButton white type="instagram" linkTarget="https://www.instagram.com/bridesurprise" />
                    <IconButton white type="pinterest" linkTarget="https://www.pinterest.de/bridesurprise" />
                    <IconButton white type="facebook" linkTarget="https://www.facebook.com/BrideSurprise.cgn" />
                </div>
            </div>

            <div>
                <StyledLink linkTarget="/impressum">Impressum</StyledLink>
            </div>
        </div>
    </div>
)

const styles = StyleSheet.create({
    Footer: {
        backgroundColor: '#fdf0f2',
        justifyContent: 'center',
        width: '100%',
        marginTop: 50,
    },
    content: {
        maxWidth: 1280,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: 15,
        gridRowGap: 15,
        padding: 40,

        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
            gridColumnGap: 0,
            gridTemplateRows: 'auto',
            gridRowGap: 40,
            padding: '40px 20px 0 20px',
        },
    },
    block: {
        flexDirection: 'column',

        '@media (max-width: 768px)': {
            marginBottom: 0,
        },
    },
    icons: {
        display: 'grid',
        gridTemplateColumns: '40px 40px 40px',
        gridColumnGap: 40,
        marginBottom: 20,
    },
})

export default Footer

export default [
    {
        name: 'Unser Angebot',
        slug: 'unsere-angebote',
    },
    {
        name: 'Unsere Geschichten',
        slug: 'unsere-geschichten',
    },
    {
        name: 'Über uns',
        slug: 'ueber-uns',
    },
    {
        name: 'Kontakt',
        slug: 'kontakt',
    },
]

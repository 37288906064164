import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import _includes from 'lodash/includes'
import React from 'react'

export interface StyledLinkProps {
    children: string
    linkTarget: string
    small?: boolean
}

const StyledLink: React.FC<StyledLinkProps> = ({ children, linkTarget, small }): JSX.Element => {
    if (_includes(linkTarget, 'http') || _includes(linkTarget, 'mailto')) {
        return (
            <a
                className={css(styles.StyledLink, small && styles.small)}
                rel="noreferrer"
                target="_blank"
                href={linkTarget}
            >
                {children}
            </a>
        )
    } else {
        return (
            <Link className={css(styles.StyledLink, small && styles.small)} to={linkTarget}>
                {children}
            </Link>
        )
    }
}

StyledLink.defaultProps = {
    small: false,
}

const styles = StyleSheet.create({
    StyledLink: {
        color: '#000',
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 1.4,
    },
    small: {
        fontSize: 15,
    },
})

export default StyledLink

import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'

export interface ContentProps {
    children: JSX.Element | JSX.Element[]
    align?: 'left' | 'center' | 'right'
}

const Content: React.FC<ContentProps> = ({ children, align }: ContentProps): JSX.Element => {
    return <div className={css(styles.Content, styles[align])}>{children}</div>
}

Content.defaultProps = {
    align: 'left',
}

const styles = StyleSheet.create({
    Content: {
        width: '100%',
        maxWidth: 1280,
        padding: '0 40px',
        flexDirection: 'column',

        '@media (max-width: 1023px)': {
            padding: '0 20px',
        },
    },
    left: {
        alignItems: 'flex-start',
    },
    center: {
        alignItems: 'center',
    },
    right: {
        alignItems: 'flex-end',
    },
})

export default Content

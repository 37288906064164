import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'

export interface CopyProps {
    children: React.ReactNode | React.ReactNode[]
    centered?: boolean
    small?: boolean
}

const Copy: React.FC<CopyProps> = ({ children, centered, small }: CopyProps): JSX.Element => (
    <div className={css(styles.Copy, centered && styles.centered, small && styles.small)}>{children}</div>
)

Copy.defaultProps = {
    centered: false,
    small: false,
}

const styles = StyleSheet.create({
    Copy: {
        fontSize: 18,
        lineHeight: 1.4,
        marginBottom: 20,
        display: 'inline-block',
        fontWeight: 300,
    },
    centered: {
        textAlign: 'center',
    },
    small: {
        fontSize: 15,
    },
})

export default Copy

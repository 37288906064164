import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'

export interface StageProps {
    headline?: string
    text?: string
    linkText?: string
    linkTarget?: string
    image: {
        fluid: FluidObject
        file?: {
            fileName: string
        }
        description?: string
        title?: string
    }
    small?: boolean
    fullWidth?: boolean
}

const Stage: React.FC<StageProps> = ({
    headline,
    text,
    linkText,
    linkTarget,
    image,
    small,
    fullWidth,
}: StageProps): JSX.Element => (
    <div className={css(styles.Stage, small && styles.StageSmall, fullWidth && styles.StageFullWidth)}>
        <Img
            className={css(styles.image, small && styles.imageSmall)}
            fluid={image.fluid}
            title={image.title ? image.title : image.file?.fileName}
            alt={image.description ? image.description : image.title}
        />

        {(headline || text) && (
            <div className={css(styles.content)}>
                <div className={css(styles.center)}>
                    <div className={css(styles.headline)}>{headline}</div>
                    <div className={css(styles.copy)}>
                        {text}

                        {linkTarget && linkText && (
                            <Link to={linkTarget} className={css(styles.link)}>
                                {linkText}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        )}
    </div>
)

Stage.defaultProps = {
    small: false,
    fullWidth: false,
}

const styles = StyleSheet.create({
    Stage: {
        maxWidth: 1200,
        width: '100%',
        height: '46vw',
        maxHeight: 560,
        position: 'relative',
        marginBottom: 60,

        '@media (max-width: 1023px)': {
            height: 'auto',
            flexDirection: 'column',
            maxHeight: 'unset',
        },
    },
    StageFullWidth: {
        maxWidth: '100%',
    },
    StageSmall: {
        maxHeight: 250,
    },
    image: {
        width: '100%',
        height: 'auto',
        maxHeight: 560,
        objectFit: 'cover',
        objectPosition: 'center top',
        '@media (max-width: 1023px)': {
            maxHeight: 250,
        },
    },
    imageSmall: {
        objectPosition: 'center center',
    },
    content: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(255,255,255, 0.6)',
        padding: '30px',
        justifyContent: 'center',

        '@media (max-width: 1023px)': {
            position: 'unset',
        },
    },
    center: {
        maxWidth: 500,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    headline: {
        textAlign: 'center',
        fontSize: 22,
        fontFamily: 'Lora, serif',
        marginBottom: 16,
        textTransform: 'uppercase',
    },
    copy: {
        textAlign: 'center',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 300,
        flexDirection: 'column',
    },
    link: {
        color: '#000',
        fontWeight: 400,
        marginTop: 10,
        display: 'block',
    },
})

export default Stage

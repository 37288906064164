import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'

export interface HeadlineProps {
    type: 'h1' | 'h2' | 'h3' | 'h4'
    text: string
    left?: boolean
    horizontalLine?: boolean
}

const Headline: React.FC<HeadlineProps> = ({ type, text, left, horizontalLine }: HeadlineProps): JSX.Element => (
    <div
        className={css(
            styles.Headline,
            styles[type],
            left && styles.HeadlineLeft,
            horizontalLine && styles.HeadlineHorizontalLine,
        )}
    >
        <div className={css(styles.text, horizontalLine && styles.textHorizontalLine)}>{text}</div>
    </div>
)

Headline.defaultProps = {
    type: 'h1',
    horizontalLine: false,
}

const styles = StyleSheet.create({
    Headline: {
        fontFamily: 'Lora',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    HeadlineLeft: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    HeadlineHorizontalLine: {
        ':after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            width: '100%',
            height: 1,
            backgroundColor: '#333',
            borderTop: '1px solid #ccc',
        },
    },
    h1: {
        fontSize: 30,
        marginBottom: 50,
    },
    h2: {
        fontSize: 26,
        marginTop: 10,
        marginBottom: 30,
    },
    h3: {
        fontSize: 22,
        marginBottom: 20,
    },
    h4: {
        fontSize: 18,
        marginBottom: 20,
    },
    text: {
        zIndex: 2,
    },
    textHorizontalLine: {
        backgroundColor: '#fff',
        padding: '0 25px',
    },
})

export default Headline

import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import React from 'react'
import Navigation, { LinkType } from './Navigation'

export interface HeaderProps {
    links: LinkType[]
    activeLink: string
    small: boolean
    isMobile: boolean
}

const Header: React.FC<HeaderProps> = ({ links, activeLink, small, isMobile }: HeaderProps): JSX.Element => {
    const [showNavigation, setShowNavigation] = React.useState(false)

    return (
        <div className={css(styles.Header, small && styles.HeaderSmall)}>
            <Link to={'/'}>
                <img
                    alt="Bride Surprise"
                    className={css(styles.logo, small && styles.logoSmall)}
                    src={require('../img/logo.png')}
                />
            </Link>

            {!small && !isMobile && <div className={css(styles.name)}>Bride Surprise</div>}

            {isMobile && (
                <img
                    onClick={() => setShowNavigation(true)}
                    src={require('../img/icons/menu.svg')}
                    className={css(styles.menu)}
                    alt="Menu"
                />
            )}

            {((isMobile && showNavigation) || !isMobile) && (
                <Navigation
                    handleCloseClick={() => setShowNavigation(false)}
                    links={links}
                    activeLink={activeLink}
                    fullscreen={isMobile}
                />
            )}
        </div>
    )
}

Header.defaultProps = {
    small: false,
    isMobile: false,
}

const styles = StyleSheet.create({
    Header: {
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 35,
        paddingBottom: 20,

        '@media (max-width: 768px)': {
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingTop: 20,
            paddingBottom: 20,
            width: '100%',
        },
    },
    HeaderSmall: {
        position: 'relative',
        flexDirection: 'row',
        height: 60,
        minHeight: 60,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 110,
    },
    logo: {
        width: 80,
        height: 80,
        marginBottom: 20,

        '@media (max-width: 768px)': {
            marginLeft: 20,
            marginBottom: 0,
        },
    },
    logoSmall: {
        width: 70,
        height: 70,
        position: 'absolute',
        top: 10,
        left: 0,
        zIndex: 2,
    },
    menu: {
        width: 40,
        height: 40,
        marginRight: 20,
        cursor: 'pointer',
    },
    name: {
        fontFamily: 'Lora',
        fontSize: 30,
        marginBottom: 40,

        '@media (max-width: 768px)': {
            display: 'none',
            textAlign: 'center',
            marginBottom: 0,
            whiteSpace: 'nowrap',
        },
    },
})

export default Header

import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'

const icons = {
    facebook: require('../img/icon-facebook.png'),
    instagram: require('../img/icon-instagram.png'),
    pinterest: require('../img/icon-pinterest.png'),
}

export interface IconButtonProps {
    type: 'facebook' | 'instagram' | 'pinterest'
    linkTarget: string
    white: boolean
}

const IconButton: React.FC<IconButtonProps> = ({ type, linkTarget, white }: IconButtonProps): JSX.Element => (
    <a
        href={linkTarget}
        aria-label={type + ' Bride Surprise'}
        target="_blank"
        rel="noreferrer"
        className={css(styles.IconButton, white && styles.IconButtonWhite)}
    >
        <img className={css(styles.image)} src={icons[type]} alt="" />
    </a>
)

IconButton.defaultProps = {
    white: false,
}

const styles = StyleSheet.create({
    IconButton: {
        transition: 'all 0.3s ease-in-out',
        backgroundColor: '#fdf0f2',
        height: 40,
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #000',
        cursor: 'pointer',
        userSelect: 'none',
        textDecoration: 'none',
        color: '#000',
        display: 'flex',

        ':hover': {
            opacity: 0.7,
        },
    },
    IconButtonWhite: {
        backgroundColor: '#fff',
    },
    image: {
        height: 20,
        objectFit: 'contain',
    },
})

export default IconButton

import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import 'typeface-lato'
import 'typeface-lora'
import links from '../data/links'
import Footer from './Footer'
import Header from './Header'
import './layout.css'
import CookieConsent from 'react-cookie-consent'

export interface LayoutProps {
    activeLink: string
    children: JSX.Element | JSX.Element[]
}

export default class Layout extends React.PureComponent<LayoutProps> {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    state = {
        windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    }

    handleWindowResize = (): void => {
        if (typeof window !== 'undefined' && this.state.windowWidth !== window.innerWidth) {
            this.setState({ windowWidth: window.innerWidth })
        }
    }

    componentDidMount(): void {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.handleWindowResize)
        }
    }

    componentWillUnmount(): void {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.handleWindowResize)
        }
    }

    render(): JSX.Element {
        const { activeLink, children } = this.props

        return (
            <div className={css(styles.layout)}>
                <CookieConsent acceptOnScroll acceptOnScrollPercentage={10} buttonText="OK">
                    Diese Website verwendet Cookies
                </CookieConsent>

                <Helmet
                    title={'Bride Surprise'}
                    meta={[
                        {
                            name: 'description',
                            content:
                                'Du bist auf der Suche nach ausgefallenen Ideen für den JGA Eurer zukünftigen Braut? Dann bist Du bei uns genau richtig.',
                        },
                        { name: 'keywords', content: 'Bride, Braut, Junggesellinnen, Junggesellinnenabschied' },
                    ]}
                >
                    <html lang="de" />
                </Helmet>

                <Header
                    isMobile={this.state.windowWidth < 769}
                    small={activeLink !== ''}
                    links={links}
                    activeLink={activeLink}
                />

                <div className={css(styles.children)}>{children}</div>

                <Footer />
            </div>
        )
    }
}

const styles = StyleSheet.create({
    layout: {
        backgroundColor: '#fff',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '100vh',
    },
    children: {
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        width: '100%',
    },
})

import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import React from 'react'

export interface LinkType {
    slug: string
    name: string
}

export interface NavigationProps {
    links: LinkType[]
    activeLink: string
    fullscreen?: boolean
    handleCloseClick?: () => void
}

const Navigation: React.FC<NavigationProps> = ({
    links,
    activeLink,
    fullscreen,
    handleCloseClick,
}: NavigationProps): JSX.Element => (
    <div className={css(styles.Navigation)}>
        {fullscreen && (
            <img
                alt="Menü schließen"
                onClick={handleCloseClick}
                className={css(styles.close)}
                src={require('../img/icons/close.svg')}
            />
        )}

        {links.map((link: LinkType) => {
            return (
                <Link
                    key={link.slug}
                    to={`/${link.slug}/`}
                    className={css(styles.link, activeLink === link.slug && styles.linkActive)}
                >
                    {link.name}
                </Link>
            )
        })}
    </div>
)

Navigation.defaultProps = {
    fullscreen: false,
}

const styles = StyleSheet.create({
    Navigation: {
        display: 'flex',
        width: '100%',
        maxWidth: 1200,
        justifyContent: 'center',
        position: 'relative',

        '@media (max-width: 768px)': {
            position: 'fixed',
            zIndex: 10,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    close: {
        position: 'absolute',
        top: 40,
        right: 40,
        width: 40,
        height: 40,
    },
    link: {
        transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
        fontSize: 14,
        lineHeight: '22px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: '#000',
        borderBottom: '1px solid transparent',
        padding: '8px 15px',

        ':hover': {
            color: '#666',
            backgroundColor: 'rgba(253, 240, 241, 0.5);',
        },

        '@media (max-width: 768px)': {
            fontSize: 20,
            padding: '20px',
        },
    },
    linkActive: {
        backgroundColor: '#fdf0f1',

        ':hover': {
            color: '#000',
            backgroundColor: '#fdf0f1',
        },
    },
})

export default Navigation
